import {Injectable} from '@angular/core';
import {ActorId, IServerApi} from '@service-and-repairs/awpintegrationlib';
import {ReplaySubject, Subject} from 'rxjs';
import {AwpClientLibService} from '../../../services/awp-client-lib.service';
import {Util} from '../../../util/util';
import {AnalyticsAction} from '../../analytics/enums/analytics-action';
import {AnalyticsService} from '../../analytics/services/analytics.service';
import {UserData} from '../../user/models/user-data';
import {UserService} from '../../user/services/user.service';
import {ServiceCase} from './service-case';

@Injectable({
  providedIn: 'root'
})
export class ServiceCaseHolder {

  private awpLib: IServerApi;

  readonly serviceCases: ServiceCase[] = [];

  activeServiceCaseIndex = -1;

  activeServiceCaseChanged = new Subject<void>();

  serviceCaseSelected = new Subject<string | undefined>();

  serviceCaseClosed = new Subject<string>();

  numberOfElementsInActiveCase = new ReplaySubject<number>(1);

  LOCAL_STORAGE_KEY_FOR_OPEN_CASE_TABS = 'externalIdsOfOpenCaseTabs';

  private currentCaseId = null;

  constructor(awpClientLibService: AwpClientLibService,
              private analyticsService: AnalyticsService,
              private userService: UserService) {
    this.awpLib = awpClientLibService.serverApi;

    this.userService.userSubject.subscribe(user => this.onUserChanged(user));
    this.subscribeToDeletedServiceCase();
  }

  getActiveCase(): ServiceCase {
    return this.serviceCases[this.activeServiceCaseIndex] || null;
  }

  openServiceCaseOrSetActive(serviceCase: ServiceCase, setActive: boolean): void {
    let tabIndex: number = this.getTabIndexByServiceCaseId(serviceCase.getExternalId());
    if (tabIndex < 0) {
      tabIndex = this.openServiceCaseInNewTab(serviceCase);
      this.updateOpenCasesInLocalStorage();
    } else {
      this.updateServiceCaseInTab(serviceCase, tabIndex);
    }
    if (setActive) {
      this.setActiveCase(tabIndex);
    } else if (tabIndex === this.activeServiceCaseIndex) {
      this.onActiveCaseChanged();
    }
  }

  sortServiceCases(sortFunc: (left: ServiceCase, right: ServiceCase) => number): void {
    const activeServiceCaseId = this.getActiveCase()?.getExternalId();

    this.serviceCases.sort(sortFunc);

    // reset active service case index
    if (activeServiceCaseId) {
      const index = this.getTabIndexByServiceCaseId(activeServiceCaseId);
      this.setActiveCase(index);
    }
  }

  closeServiceCaseById(externalId: string, origin: string): void {
    const tabIndex: number = this.getTabIndexByServiceCaseId(externalId);
    if (tabIndex >= 0) {
      this.analyticsService.postServiceCaseCrudEvent(
        AnalyticsAction.CLOSE_OPEN_TAB,
        origin,
        this.serviceCases[tabIndex]
      );

      this.awpLib.publishServiceCaseTabClosed(externalId);

      this.serviceCaseClosed.next(externalId);
      if (tabIndex === this.activeServiceCaseIndex) { // active service case closed, switch to next open tab
        this.moveActiveCaseIndex(tabIndex);
        if (this.activeServiceCaseIndex >= 0) { // switched to next open tab (otherwise all tabs are closed)
          this.analyticsService.postServiceCaseCrudEvent(
            AnalyticsAction.SWITCH_TO_OPEN_TAB,
            externalId,
            this.getActiveCase()
          );
        }
      }

      // Delete from cases list
      this.serviceCases.splice(tabIndex, 1);
      if (tabIndex <= this.activeServiceCaseIndex) {
        // Active case remains the same, but index shifts left
        this.activeServiceCaseIndex = this.activeServiceCaseIndex - 1;
      }
      this.onActiveCaseChanged();
      this.updateOpenCasesInLocalStorage();
    }
  }

  isServiceCaseOpen(externalId: string): boolean {
    return this.serviceCases.some(serviceCase => serviceCase.getExternalId() === externalId);
  }

  private getTabIndexByServiceCaseId(serviceCaseId: string): number {
    return this.serviceCases.findIndex(sc => sc.getExternalId() === serviceCaseId); // May return -1
  }

  onActiveCaseChanged(): void {
    this.activeServiceCaseChanged.next(); // Notify subscribers within the webapp (e.g. to update the deep link)
    this.awpLib.publishActiveCase(this.getActiveCase());

    if (this.getActiveCase()) {
      this.numberOfElementsInActiveCase.next(this.getActiveCase().getNumberOfElementsInBasket());
    } else {
      this.numberOfElementsInActiveCase.next(undefined);
    }

    this.notifySubscribersIfNewCaseSelected();
  }

  getServiceCaseIdsFromLocalStorage(): string[] {
    return JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY_FOR_OPEN_CASE_TABS) || '[]');
  }

  private notifySubscribersIfNewCaseSelected(): void {
    const newCaseId: string = this.getActiveCase()?.getExternalId();
    if (this.currentCaseId !== newCaseId) {
      this.currentCaseId = newCaseId;
      this.serviceCaseSelected.next(newCaseId);
    }
  }

  private updateOpenCasesInLocalStorage(): void {
    const externalIdsOfOpenCases = this.serviceCases.map(serviceCase => serviceCase.getExternalId());
    localStorage.setItem(this.LOCAL_STORAGE_KEY_FOR_OPEN_CASE_TABS, JSON.stringify(externalIdsOfOpenCases));
  }

  private setActiveCase(tabIndex: number): void {
    this.activeServiceCaseIndex = tabIndex;
    this.onActiveCaseChanged();
  }

  private openServiceCaseInNewTab(serviceCase: ServiceCase): number {
    this.serviceCases.push(serviceCase);
    return this.serviceCases.length - 1;
  }

  private updateServiceCaseInTab(serviceCase: ServiceCase, tabIndex: number): void {
    this.serviceCases[tabIndex] = serviceCase;
  }

  private moveActiveCaseIndex(tabIndex: number): void {
    if (tabIndex !== this.serviceCases.length - 1) {
      // Select the right neighbor
      this.setActiveCase(tabIndex + 1);
    } else {
      // ... or the left one if there is none to the right
      this.setActiveCase(tabIndex - 1);
    }
  }

  private onUserChanged(user: UserData) {
    if (user) {
      this.serviceCases.forEach(serviceCase => serviceCase.applyUserData(user.getMileageUnit(), user.getLocale()));
    }
  }

  private subscribeToDeletedServiceCase(): void {
    this.awpLib.subscribeToDeletedCase((externalServiceCaseId: string, origin: ActorId) => {
      this.analyticsService.postServiceCaseCrudEvent(
        AnalyticsAction.DELETE,
        origin,
        ServiceCase.fromPlainObject({externalId: externalServiceCaseId})
      );
      this.closeServiceCaseById(externalServiceCaseId, origin);
    });
  }

  addServiceCaseIsBeingSavedEvent(serviceCaseId: string): string {
    const eventId = Util.createRandomUuid();
    const serviceCase = this.findByExternalId(serviceCaseId);
    if (serviceCase) {
      serviceCase.saveEventIds.add(eventId);
      setTimeout(() => {
        serviceCase.saveEventIds.delete(eventId);
      }, 2000); // Auto-delete events to keep the icon from spinning forever when events are not properly removed
    }
    return eventId;
  }

  removeServiceCaseIsBeingSavedEvent(serviceCaseId: string, eventId: string): void {
    const serviceCase = this.findByExternalId(serviceCaseId);
    if (serviceCase) {
      setTimeout(() => {
        serviceCase.saveEventIds.delete(eventId);
      }, 100); // Improves visibility of very short-lived save events
    }
  }

  findByExternalId(externalId: string): ServiceCase {
    return this.serviceCases.find((serviceCase: ServiceCase): boolean => serviceCase.getExternalId() === externalId);
  }
}
