<div class="tab-navigation" [ngClass]="{'large': (userService.userSubject | async).getServiceCaseTabLineCount() === 4}">
  <div class="case-bar-controls">
    <input ds-input type="text" autocomplete="off"
           [placeholder]="(loading ? 'vehicle.loadingVehicle' : 'case.searchInputPlaceHolder') | translate"
           (focus)="showSearchOverlay()"
           (click)="showSearchOverlay()"
           [attr.disabled]="loading ? true : null"/>
    <button ds-button icon="search" class="case-search-button"
            (click)="openNewServiceCaseWithoutVehicle()"
            [disabled]="loading">
    </button>
    @if (userService.userSubject.getValue()?.getContext() !== 'B2E') {
      <button ds-button icon="key" class="key-pool-search-button ms-3x"
              (click)="showSearchOverlayKeyPool()"
              [disabled]="loading">
      </button>
    }
  </div>

  <div class="case-tab-container" #tabContainer>
    @for (serviceCase of serviceCaseHolder.serviceCases; track serviceCase; let i = $index) {
      <div class="case-tab-with-tooltip">
        <app-service-case-tab
          [ngStyle]="{'width': tabWidth + 'px'}"
          [ngClass]="{'is-inactive' : serviceCaseHolder.activeServiceCaseIndex !== i || loading, 'no-gradient-and-padding' : tabWidth < 18}"
          [serviceCase]="serviceCase"
          [user]="userService.userSubject | async"
          [isLarge]="(userService.userSubject | async)?.getServiceCaseTabLineCount() === 4"
          (click)="onCaseTabClick(serviceCase)"
          (mouseout)="updateTabWidth()"
          (mouseenter)="onTabMouseOver($event)"
        ></app-service-case-tab>
        @if (tabWidth < 290) {
          <app-service-case-tab
            class="case-tab-tooltip"
            [isTooltip]="true"
            [serviceCase]="serviceCase"
            [user]="userService.userSubject | async"
            [isLarge]="(userService.userSubject | async)?.getServiceCaseTabLineCount() === 4"
          ></app-service-case-tab>
        }
      </div>
    }
  </div>

  @if (serviceCaseHolder.getActiveCase() && !shortListViewState.visible) {
    <div class="shopping-cart-container p-3x">
      <app-shopping-cart-button [active]="false" (click)="showShortList()"></app-shopping-cart-button>
    </div>
  }
</div>

<app-vehicle-search-overlay #searchOverlay></app-vehicle-search-overlay>

<app-vehicle-selection #vehicleSelectionOverlay></app-vehicle-selection>
<app-case-selection #caseSelectionOverlay></app-case-selection>

@if (customerLanguagePopupRequired()) {
  <app-customer-language-popup></app-customer-language-popup>
}
