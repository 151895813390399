import {Location} from '@angular/common';
import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {IServerApi} from '@service-and-repairs/awpintegrationlib';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {AnalyticsAction} from '../../../core/analytics/enums/analytics-action';
import {VehicleSearchOrigin} from '../../../core/analytics/enums/vehicle-search-origin';
import {AnalyticsService} from '../../../core/analytics/services/analytics.service';
import {CustomerLoader} from '../../../core/customer/services/customer.loader';
import {DealerDataService} from '../../../core/dealer/services/dealer-data.service';
import {ServiceCase} from '../../../core/service-case/models/service-case';
import {ServiceCaseHolder} from '../../../core/service-case/models/service-case-holder';
import {ServiceCaseManager} from '../../../core/service-case/models/service-case-manager';
import {UserService} from '../../../core/user/services/user.service';
import {AwpClientLibService} from '../../../services/awp-client-lib.service';
import {
  VehicleSearchOverlayTab
} from '../../vehicle/vehicle-search-overlay/vehicle-search-overlay-tab/vehicle-search-overlay-tab';
import {VehicleSearchOverlayComponent} from '../../vehicle/vehicle-search-overlay/vehicle-search-overlay.component';
import {
  VehicleSelectionOverlayComponent
} from '../../vehicle/vehicle-selection-overlay/vehicle-selection-overlay.component';
import {CaseSelectionOverlayComponent} from './selection/case-selection-overlay.component';

@Component({
  selector: 'app-service-case-bar',
  templateUrl: './service-case-bar.component.html',
  styleUrls: ['./service-case-bar.component.scss']
})
export class ServiceCaseBarComponent implements AfterViewInit, OnDestroy, VehicleSearchOverlayTab {

  @Input()
  shortListViewState: {visible: boolean} = {visible: false};

  awpLib: IServerApi;
  tabWidth: number = 0;
  loading: boolean = false;

  private hasSubscribedToDeepLinkQueryParams: boolean = false;

  @ViewChild('searchOverlay', {static: false})
  private readonly vehicleSearchOverlay: VehicleSearchOverlayComponent;

  @ViewChild('vehicleSelectionOverlay', {static: false})
  private readonly vehicleSelectionOverlay: VehicleSelectionOverlayComponent;

  @ViewChild('caseSelectionOverlay', {static: false})
  private readonly caseSelectionOverlay: CaseSelectionOverlayComponent;

  @ViewChild('tabContainer', {static: false})
  private tabContainer: ElementRef;

  private queryParamsSubscription: Subscription;
  private firstQueryParamChange: boolean = true;

  constructor(awpClientLibService: AwpClientLibService,
              private readonly analyticsService: AnalyticsService,
              public serviceCaseHolder: ServiceCaseHolder,
              private readonly serviceCaseManager: ServiceCaseManager,
              private readonly translate: TranslateService,
              private readonly route: ActivatedRoute,
              private readonly location: Location,
              private readonly router: Router,
              public userService: UserService,
              private readonly dealerDataService: DealerDataService,
              private readonly customerLoader: CustomerLoader) {
    this.awpLib = awpClientLibService.serverApi;

    userService.userSubject.subscribe((): void => {
      if (!this.hasSubscribedToDeepLinkQueryParams) {
        this.hasSubscribedToDeepLinkQueryParams = true;
        this.checkParams();
      }
    });

    this.serviceCaseHolder.activeServiceCaseChanged.subscribe(() => this.setDeepLinkUrl());
    // make sure route parameters are not lost if user navigates
    this.router.events
      .pipe(filter((event): boolean => event instanceof NavigationEnd))
      .subscribe(this.setDeepLinkUrl.bind(this));

    this.serviceCaseManager.caseLoadingStarted.subscribe((): void => {
      this.loading = true;
    });
    this.serviceCaseManager.caseLoadingFinished.subscribe(
      (): void => {
        this.updateTabWidth();
        this.loading = false;
      }
    );
  }

  titleResource: string;
  src?: string;
  showLoadingIndicator?: boolean;
  showErrorIndicator?: boolean;

  ngAfterViewInit(): void {
    this.serviceCaseManager.setSelectionOverlays(
      this.vehicleSelectionOverlay,
      this.caseSelectionOverlay
    );
    this.setUpResizeObserver();
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription?.unsubscribe();
  }

  showSearchOverlay(): void {
    this.vehicleSearchOverlay.show();
  }

  showSearchOverlayKeyPool(): void {
    this.vehicleSearchOverlay.showKeyPool();
  }

  openNewServiceCaseWithoutVehicle(): void {
    this.serviceCaseManager.openNewServiceCaseWithoutVehicle();
  }

  updateTabWidth(): void {
    const tabContainer = this.tabContainer?.nativeElement;
    if (this.serviceCaseHolder.serviceCases.length > 0 && tabContainer?.offsetWidth) {
      // container should have 16px space on the right
      const width = (tabContainer.offsetWidth - 16) / this.serviceCaseHolder.serviceCases.length;
      // each tab has a padding-right of 3px
      this.tabWidth = width - 3;
    } else {
      this.tabWidth = 290;
    }
  }

  onCaseTabClick(serviceCase: ServiceCase): void {
    const previousServiceCaseId: string = this.serviceCaseHolder.getActiveCase()?.getExternalId();
    this.serviceCaseHolder.openServiceCaseOrSetActive(serviceCase, true);
    this.analyticsService.postServiceCaseCrudEvent(
      AnalyticsAction.SWITCH_TO_OPEN_TAB,
      previousServiceCaseId,
      serviceCase
    );
  }

  onTabMouseOver(event: MouseEvent): void {
    // tooltip is only visible if tab does not have full width
    if (this.tabWidth < 290) {
      // make sure case tab tooltip stays inside screen
      const tabTooltip: HTMLElement = (event.target as HTMLElement).nextSibling as HTMLElement;
      tabTooltip?.style?.removeProperty('right');
      if (tabTooltip?.getBoundingClientRect().right > window.innerWidth) {
        tabTooltip.style.right = '0';
      }
    }
  }

  showShortList(): void {
    this.shortListViewState.visible = true;
  }

  /**
   * Sets the query parameters of the url according to the currently opened service case.
   */
  private setDeepLinkUrl(): void {
    let queryParam: string;
    const activeCase: ServiceCase = this.serviceCaseHolder.getActiveCase();
    if (activeCase?.getExternalId()) {
      queryParam = 'service-case-id=' + activeCase.getExternalId();
    }
    const location: string = this.location.path().split('?')[0];
    this.location.go(location, queryParam);
  }

  /**
   * If the query parameter 'service-case-id' is set, the service case with this external id will be loaded and shown.
   * If the query parameters 'vin' or 'typecode' are set, the (virtual) vehicle will be loaded and added to a new
   * service case. The new service case will be shown.
   * If the query parameter 'business-number' is set, the related business will be chosen as the selected outlet.
   */
  private checkParams(): void {
    this.queryParamsSubscription?.unsubscribe();
    this.queryParamsSubscription = this.route.queryParams.subscribe((params: Params): void => {
      if (params?.['business-number']) {
        this.dealerDataService.switchToOutlet(params['business-number']);
      }
      if (params?.['service-case-id']) {
        this.handleServiceCaseIdQueryParameter(params['service-case-id'], params.vin, this.firstQueryParamChange);
      } else if (params?.vin) {
        this.handleVinQueryParameter(params.vin);
      } else if (params?.typecode) {
        this.handleTypeCodeQueryParameter(params.typecode);
      }
      this.firstQueryParamChange = false;
    });
  }

  private handleServiceCaseIdQueryParameter(serviceCaseId: string, vin: string, restoreServiceCases: boolean): void {
    const serviceCaseIdsToRestore: string[] =
      restoreServiceCases ?
        this.serviceCaseHolder.getServiceCaseIdsFromLocalStorage() :
        [];

    this.serviceCaseManager.openExistingServiceCaseById(serviceCaseId, VehicleSearchOrigin.CASE_ID)
      .catch((): void => {
        // service case could not be loaded, try VIN instead
        if (vin) {
          this.handleVinQueryParameter(vin);
        }
      })
      .finally((): void => {
        if (serviceCaseIdsToRestore.length > 0) {
          this.serviceCaseManager.restoreServiceCases(serviceCaseIdsToRestore, serviceCaseId);
        }
      });
  }

  private handleVinQueryParameter(vin: string): void {
    if (vin.length !== 17) {
      this.awpLib.notifyWarning(this.translate.instant('common.deepLinkVinInvalid'));
    } else {
      this.serviceCaseManager.selectAndOpenServiceCaseByVin(vin, VehicleSearchOrigin.VIN17);
    }
  }

  private handleTypeCodeQueryParameter(typeCode: string): void {
    if (typeCode.length !== 4) {
      this.awpLib.notifyWarning(this.translate.instant('common.deepLinkTypeInvalid'));
    } else {
      this.serviceCaseManager.openNewServiceCaseByTypeCode(typeCode, VehicleSearchOrigin.TYPE_CODE);
    }
  }

  private setUpResizeObserver(): void {
    const resizeObserver: ResizeObserver = new ResizeObserver((): void => this.updateTabWidth());

    resizeObserver.observe(this.tabContainer?.nativeElement);
  }

  customerLanguagePopupRequired(): boolean {
    return this.customerLoader.isCustomerLanguageCheckEnabled();
  }
}
