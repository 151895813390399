import {TransferFormat, TransferMode} from '@service-and-repairs/dms-api';
import {DcomDmsSettings} from './dcom-dms-settings';
import {EtkDmsSettings} from './etk-dms-settings';

export class DmsSettings {
  transferMode: TransferMode = TransferMode.Undefined;
  transferFormat: TransferFormat = TransferFormat.Unknown;
  transferFileStorageLocation: string = undefined;
  lockJobsAfterTransfer: boolean = false;
  lockJobsAfterTransferAsPreOrder: boolean = false;
  dcom: DcomDmsSettings = new DcomDmsSettings();
  etk: EtkDmsSettings = new EtkDmsSettings();
}
