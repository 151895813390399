export class EtkDmsSettings {
  isEnabled: boolean = false;
  employeeNumber: string = '';
  customerNumber: number;
  cashSalesCustomerNumber: number;
  orderNumber: string = '';
  password: string = '';
  isAutomaticPartsInfoEnabled: boolean = false;
  isUsDirectEnabled: boolean = false;
  usDirectFilename: string = '';
  useDcomForPricesAndAvailabilities: boolean = false;
}
