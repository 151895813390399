import {Injectable} from '@angular/core';
import {
  AppointmentInfo,
  AppointmentSearchInput,
  AppointmentSearchResult,
  Customer,
  CustomerAddressEnum,
  CustomerDataResult,
  CustomerVehicleRelationEnum,
  DcomDateFormat,
  DealerData,
  Dms,
  EtkLicensePlateSearchInput,
  EtkSettings,
  EtkVehicleInfo,
  ExtendedVehicleInfo,
  OrderInfo,
  OrderSearchInput,
  OrderSearchResult,
  PricesAndAvailabilitiesRequestMethod,
  ServiceContractsConversionSettings,
  Settings,
  TransferFormat,
  TransferMode,
  VehicleSearchInput,
  VehicleSearchResult
} from '@service-and-repairs/dms-api';
import {IntercomServer} from '@service-and-repairs/dms-common';
import {DialogCoordinatorImpl, DmsImpl, DmsStub} from '@service-and-repairs/dms-impl';
import {Observable, ReplaySubject} from 'rxjs';
import {EtkConfiguration} from '../../core/configuration/interfaces/etk-configuration';
import {OutletRelatedConfiguration} from '../../core/configuration/interfaces/outlet-related-configuration';
import {ConfigurationLoader} from '../../core/configuration/services/configuration.loader';
import {UserData} from '../../core/user/models/user-data';
import {UserService} from '../../core/user/services/user.service';
import {ThinClientModeService} from '../../services/thin-client-mode.service';
import {Util} from '../../util/util';
import {DmsSettings} from './dms-settings';
import {OrderAndAppointmentSearchResult} from './order-and-appointment-search-result';

@Injectable({
  providedIn: 'root'
})
export class DmsService {
  // DMS Transfer
  dmsSettings: DmsSettings = new DmsSettings();
  private outletRelatedConfig: OutletRelatedConfiguration;
  private etkConfig: EtkConfiguration;

  /**
   * Will be triggered whenever any general or ETK setting changes
   */
  dmsSettingsChanged: Observable<Settings>;
  private dmsSettingsChangedSubject: ReplaySubject<Settings> = new ReplaySubject<Settings>(1);
  private previousOutletConfig: OutletRelatedConfiguration;
  private previousEtkConfig: EtkConfiguration;

  dmsApi: Dms;
  private dmsIntercom: IntercomServer = null;

  constructor(private configurationLoader: ConfigurationLoader,
              private userService: UserService) {
    this.dmsSettingsChanged = this.dmsSettingsChangedSubject.asObservable();
    userService.userSubject.subscribe(() => this.handleUserChanges());
  }

  private static loadDmsScripts(): void {
    Util.attachScript('/api/gui/dms/static/dms-ui/main.js').catch((): void => {
      console.error('DmsService: Failed to load dms-ui/main.js');
    });
    Util.attachScript('/api/gui/dms/static/dms-ui/polyfills.js').catch((): void => {
      console.error('DmsService: Failed to load dms-ui/polyfills.js');
    });
  }

  /**
   * Make sure a language code matches the format xx-XX.
   * @param languageCode A language or country code, should be in the format xx, XX or xx-XX.
   * @returns The language code in the format xx-XX or undefined if the given code does not match the format above.
   */
  private static normalizeLanguage(languageCode: string | undefined): string | undefined {
    // language can be xx-XX, xx, XX or undefined
    if (languageCode?.length === 2) {
      return languageCode.toLowerCase() + '-' + languageCode.toUpperCase();
    } else if (languageCode?.length === 5) {
      return languageCode;
    }
    return undefined;
  }

  // use static factory functions to simplify unit tests
  private static getIntercomServer(): IntercomServer {
    return new IntercomServer('dms');
  }

  private static createDmsStub(dmsApi: Dms, dmsIntercom: IntercomServer): void {
    // creating a new instance is sufficient, no need to keep a reference
    new DmsStub(dmsApi, dmsIntercom); // NOSONAR
  }

  private static createDmsImpl(dmsApiTransferSettings: Settings, dealerData: DealerData): DmsImpl {
    return new DmsImpl(dmsApiTransferSettings, dealerData, new DialogCoordinatorImpl());
  }

  searchVehiclesByLicensePlateViaDcom(licensePlate: string, resultSize: number): Promise<VehicleSearchResult> {
    if (this.dmsApi) {
      const input: VehicleSearchInput = new VehicleSearchInput(licensePlate, resultSize);
      return this.dmsApi.searchVehicles(input);
    } else {
      return Promise.reject(new Error('Dms is not initialized'));
    }
  }

  searchVehiclesByLicensePlateViaEtkDms(licensePlate: string): Promise<EtkVehicleInfo> {
    if (this.dmsApi) {
      const input: EtkLicensePlateSearchInput = new EtkLicensePlateSearchInput(licensePlate, undefined);
      return this.dmsApi.searchVehiclesByLicensePlateViaEtkDms(input);
    } else {
      return Promise.reject(new Error('Dms is not initialized'));
    }
  }

  searchOrderViaDcom(orderNumber: string, vin: string, resultSize: number): Promise<OrderSearchResult> {
    if (this.dmsApi) {
      const input: OrderSearchInput = new OrderSearchInput(
        orderNumber,
        null,
        null,
        null,
        vin?.length === 7 ? '*' + vin : vin,
        null,
        resultSize
      );
      return this.dmsApi.searchOrders(input);
    } else {
      return Promise.reject(new Error('Dms is not initialized'));
    }
  }

  searchAppointmentViaDcom(serviceAppointmentId: string,
                           vin: string,
                           resultSize: number): Promise<AppointmentSearchResult> {
    if (this.dmsApi) {
      const input: AppointmentSearchInput = new AppointmentSearchInput(
        serviceAppointmentId,
        null, null, null, null, null,
        vin?.length === 7 ? '*' + vin : vin,
        null, null, null,
        resultSize
      );
      return this.dmsApi.searchAppointments(input);
    } else {
      return Promise.reject(new Error('Dms is not initialized'));
    }
  }

  async searchOrdersAndAppointmentsViaDcom(vin: string): Promise<OrderAndAppointmentSearchResult[]> {
    if (this.dmsSettings.transferMode !== TransferMode.Dcom) {
      return Promise.resolve([]);
    }

    const [orderResult, appointmentResult] = await Promise.allSettled([
      this.searchOrderViaDcom(undefined, vin, 100),
      this.searchAppointmentViaDcom(undefined, vin, 100)
    ]);
    const orderResults: OrderInfo[] = orderResult.status === 'fulfilled' ? orderResult.value.orders : [];
    const appointmentResults: AppointmentInfo[] = appointmentResult.status === 'fulfilled' ?
      appointmentResult.value.appointments :
      [];
    return this.mergeOrderAndAppointmentResults(orderResults, appointmentResults);
  }

  searchOrderViaEtkDms(orderNumber: string): Promise<EtkVehicleInfo> {
    if (this.dmsApi) {
      return this.dmsApi.searchVehiclesByOrderNumerViaEtkDms(orderNumber);
    } else {
      return Promise.reject(new Error('Dms is not initialized'));
    }
  }

  isLicensePlateSearchEnabled(): boolean {
    return (this.dmsSettings.dcom.isLicensePlateSearchEnabled || this.dmsSettings.etk.isEnabled)
      && ThinClientModeService.thinClientMode;
  }

  isOrderSearchEnabled(): boolean {
    return (this.dmsSettings.dcom.url?.trim()?.length > 0 || this.dmsSettings.etk.isEnabled)
      && ThinClientModeService.thinClientMode;
  }

  isCustomerSearchEnabled(): boolean {
    // as there is no dedicated customer search setting, allow if DCOM is configured
    return ThinClientModeService.thinClientMode && this.dmsSettings.dcom.url?.trim()?.length > 0;
  }

  /**
   * Determine the customer language & number for a VIN from the DMS.
   * @param vin17 The 17 digit VIN.
   */
  getCustomerLanguageAndNumber(vin17: string): Promise<string[]> {
    return new Promise<string[]>((resolve, reject): void => {
      Promise.all([
          this.dmsApi.determineSingleCustomerId(vin17, undefined, [CustomerVehicleRelationEnum.USER]),
          this.dmsApi.getCustomerDataByVin(vin17)
        ]
      )
        .then((results: [string, CustomerDataResult]): void => {
          const chosenCustomer: Customer = results[1].customerList
            .find((customer: Customer): boolean => customer.customerId.dmsId === results[0]);
          if (chosenCustomer) {

            // look for language
            const language: string = chosenCustomer.personalData?.language?.internalKey;
            if (language) {
              console.log('DmsService: Got language ' + language + ' from DMS.');
              resolve([DmsService.normalizeLanguage(language), chosenCustomer.customerId?.dmsId]);
              return;
            }

            // determine from address if language cannot be found
            let addressCountry: string;
            switch (chosenCustomer.contactData.mainAddress) {
              case CustomerAddressEnum.AdditionalAddress:
                addressCountry = chosenCustomer.contactData.additionalAddress?.country.internalKey;
                break;
              case CustomerAddressEnum.BusinessAddress:
                addressCountry = chosenCustomer.contactData.businessAddress?.country.internalKey;
                break;
              case CustomerAddressEnum.PrivateAddress:
                addressCountry = chosenCustomer.contactData.privateAddress?.country.internalKey;
                break;
            }
            console.log('DmsService: Got country ' + addressCountry + ' from DMS.');
            resolve([DmsService.normalizeLanguage(addressCountry), chosenCustomer.customerId?.dmsId]);
          } else {
            // no chosen customer found
            resolve([]);
          }
        })
        // DMS requests failed
        .catch(reject);
    });
  }

  /**
   * Returns true if DMS orders have to be in the customer's language.
   * Currently this is the case in Belgium and Luxembourg in ThinClient mode.
   */
  dmsOrderHasToBeInCustomerLanguage(): boolean {
    const userCountry: string = this.userService.userSubject.getValue().getB2XCountryCode();
    if ((userCountry === 'BE' || userCountry === 'LU') && ThinClientModeService.thinClientMode) {
      return this.outletRelatedConfig?.outletConfiguration?.multipleLanguageCheckAllowed;
    } else {
      return false;
    }
  }

  getLicensePlateByVin(vin17: string): Promise<string> {
    if (this.dmsApi) {
      return new Promise<string>((resolve, reject): void => {
        this.dmsApi.getVehicleDataByVin(vin17)
          .then((vehicle: ExtendedVehicleInfo) => resolve(vehicle.numberPlate))
          // DMS requests failed
          .catch(reject);
      });
    } else {
      return Promise.resolve(null);
    }
  }

  private init(): void {
    this.dmsIntercom = DmsService.getIntercomServer();
    this.dmsIntercom.start();
    this.subscribeToConfigChanges();
  }

  private handleUserChanges(): void {
    const user: UserData = this.userService.userSubject.getValue();
    if (user && user.getContext() !== 'B2E') {
      if (this.dmsIntercom) {
        this.instantiateDmsApi();
      } else {
        this.init();
      }
    }
  }

  private subscribeToConfigChanges(): void {
    this.configurationLoader.configChanged.subscribe((config: any): void => {
      this.outletRelatedConfig = config.outletRelatedConfig;
      this.etkConfig = config.etkConfig;
      this.initializeDms();
    });
  }

  private initializeDms(): void {
    this.calculateDmsSettingsFromConfig();
    this.instantiateDmsApi();
  }

  private calculateDmsSettingsFromConfig(): void {
    if (ThinClientModeService.thinClientMode) {
      this.calculateSettingsForThinClientMode();
    } else {
      this.calculateSettingsForBrowserMode();
    }
  }

  private isDcomConfigured(): boolean {
    return this.outletRelatedConfig?.outletConfiguration?.dcomUrl?.trim().length > 0;
  }

  private isWritingToDmsEnabled(): boolean {
    return this.outletRelatedConfig?.outletConfiguration?.dmsWriteAccessAllowed;
  }

  private isWritingToClipboardEnabled(): boolean {
    return this.outletRelatedConfig?.outletConfiguration?.dmsWriteAccessAllowed
      && this.outletRelatedConfig?.outletConfiguration?.transferMode === TransferMode.Clipboard;
  }

  private isWritingToUsDirectIsEnabled(): boolean {
    return this.etkConfig?.dmsDirect && this.etkConfig?.dmsDirectPath !== undefined;
  }

  private calculateSettingsForBrowserMode(): void {
    if (this.isWritingToClipboardEnabled()) {
      this.dmsSettings.transferMode = TransferMode.Clipboard;
      this.dmsSettings.transferFormat =
        this.outletRelatedConfig?.outletConfiguration?.transferFormat ||
        TransferFormat.Unknown;
    } else {
      this.dmsSettings.transferMode = TransferMode.Undefined;
      this.dmsSettings.transferFormat = TransferFormat.Unknown;
    }
  }

  private calculateSettingsForThinClientMode(): void {
    this.calculateSettingsForReadingFromEtkDms();
    this.calculateSettingsForReadingFromDcom();
    if (ThinClientModeService.apasMode) {
      this.calculateSettingsForWritingToApas();
    } else {
      this.calculateSettingsForWritingToDms();
    }
  }

  private calculateSettingsForReadingFromDcom(): void {
    if (this.isDcomConfigured()) {
      this.dmsSettings.dcom.url = this.outletRelatedConfig.outletConfiguration.dcomUrl;
      this.dmsSettings.dcom.isPricesAndAvailabilitiesEnabled = this.outletRelatedConfig.outletConfiguration.dmsReadAccessAllowed;
      this.dmsSettings.dcom.isAutomaticPriceRequestEnabled = this.outletRelatedConfig.outletConfiguration.retrievePricesAndAvailabilitiesAutomatically;
      this.dmsSettings.dcom.requestPricesAndAvailabilitiesPerJob = this.outletRelatedConfig.outletConfiguration.dmsOneRequestPerJobForPricesAndAvailabilities;
      this.dmsSettings.dcom.isLicensePlateSearchEnabled = this.outletRelatedConfig.outletConfiguration.airNumberPlateSearchAllowed;
      if (this.outletRelatedConfig.outletConfiguration.brandSpecificWorkplaceIdUsed) {
        this.dmsSettings.dcom.bmwWorkplaceId = this.outletRelatedConfig.outletConfiguration.bmwWorkplaceId;
        this.dmsSettings.dcom.bmwiWorkplaceId = this.outletRelatedConfig.outletConfiguration.bmwiWorkplaceId;
        this.dmsSettings.dcom.miniWorkplaceId = this.outletRelatedConfig.outletConfiguration.miniWorkplaceId;
        this.dmsSettings.dcom.rollsRoyceWorkplaceId = this.outletRelatedConfig.outletConfiguration.rollsRoyceWorkplaceId;
        this.dmsSettings.dcom.zinoroWorkplaceId = this.outletRelatedConfig.outletConfiguration.zinoroWorkplaceId;
        this.dmsSettings.dcom.bmwMotorradWorkplaceId = this.outletRelatedConfig.outletConfiguration.bmwMotorcycleWorkplaceId;
      } else {
        this.dmsSettings.dcom.workplaceId = this.outletRelatedConfig.outletConfiguration.dcomWorkplaceId;
      }
      this.dmsSettings.dcom.grpDmsOrderSplitActivated = this.outletRelatedConfig.outletConfiguration.grpDmsOrderSplitActivated;
      this.dmsSettings.dcom.grpDmsOrderSplitCostUnitAccountId = this.outletRelatedConfig.outletConfiguration.grpDmsOrderSplitCostUnitAccountId;
      this.dmsSettings.dcom.grpDmsOrderSplitCostUnitDescription = this.outletRelatedConfig.outletConfiguration.grpDmsOrderSplitCostUnitDescription;
      this.dmsSettings.dcom.sipDmsOrderSplitActivated = this.outletRelatedConfig.outletConfiguration.sipDmsOrderSplitActivated;
      this.dmsSettings.dcom.sipDmsOrderSplitCostUnitAccountId = this.outletRelatedConfig.outletConfiguration.sipDmsOrderSplitCostUnitAccountId;
      this.dmsSettings.dcom.sipDmsOrderSplitCostUnitDescription = this.outletRelatedConfig.outletConfiguration.sipDmsOrderSplitCostUnitDescription;
      this.dmsSettings.dcom.tecDmsOrderSplitActivated = this.outletRelatedConfig.outletConfiguration.tecDmsOrderSplitActivated;
      this.dmsSettings.dcom.tecDmsOrderSplitCostUnitAccountId = this.outletRelatedConfig.outletConfiguration.tecDmsOrderSplitCostUnitAccountId;
      this.dmsSettings.dcom.tecDmsOrderSplitCostUnitDescription = this.outletRelatedConfig.outletConfiguration.tecDmsOrderSplitCostUnitDescription;
    } else {
      this.dmsSettings.dcom.url = null;
      this.dmsSettings.dcom.isPricesAndAvailabilitiesEnabled = false;
      this.dmsSettings.dcom.isLicensePlateSearchEnabled = false;
    }
  }

  private calculateSettingsForWritingToDms(): void {
    if (this.isWritingToDmsEnabled()) { // DCOM/CLIPBOARD/FILE
      this.dmsSettings.transferMode =
        this.outletRelatedConfig.outletConfiguration.transferMode || TransferMode.Undefined;
      this.dmsSettings.transferFormat =
        this.outletRelatedConfig.outletConfiguration.transferFormat || TransferFormat.Unknown;
      this.dmsSettings.transferFileStorageLocation = this.outletRelatedConfig.outletConfiguration.dmsTransferFileStorageLocation;
      this.dmsSettings.lockJobsAfterTransfer = this.outletRelatedConfig.outletConfiguration.freezeJobsAfterDmsTransfer;
      this.dmsSettings.lockJobsAfterTransferAsPreOrder = this.outletRelatedConfig.outletConfiguration.freezeJobsAfterDmsTransferAsPreOrder;
      this.dmsSettings.dcom.overwriteMetadataOnUpdate = this.outletRelatedConfig.outletConfiguration.dcomOverwriteMetadataOnUpdate;
    } else {
      this.dmsSettings.transferMode = TransferMode.Undefined;
      this.dmsSettings.transferFormat = TransferFormat.Unknown;
    }
  }

  private calculateSettingsForReadingFromEtkDms(): void {
    // ETK XML Interface (read & write)
    this.dmsSettings.etk.isEnabled = this.etkConfig?.dmsAnbindung;
    this.dmsSettings.etk.isAutomaticPartsInfoEnabled = this.etkConfig ? this.etkConfig.activateDmsTeileInfo : false;
    this.dmsSettings.etk.useDcomForPricesAndAvailabilities = this.etkConfig?.useAwpDms;
    // ETK-XML Interface DMS transfer default values
    this.dmsSettings.etk.employeeNumber = this.etkConfig?.dmsMitarbeiternummer;
    this.dmsSettings.etk.password = this.etkConfig?.dmsPassword;
    this.dmsSettings.etk.orderNumber = this.etkConfig?.auftragsNr;
    this.dmsSettings.etk.customerNumber = this.etkConfig?.kundenNr;
    this.dmsSettings.etk.cashSalesCustomerNumber = this.etkConfig?.barverkaufKundennummer;
    // (ETK) US direct file interface
    this.dmsSettings.etk.isUsDirectEnabled = this.isWritingToUsDirectIsEnabled();
    this.dmsSettings.etk.usDirectFilename = this.isWritingToUsDirectIsEnabled() ?
      this.etkConfig.dmsDirectPath :
      undefined;
  }

  private calculateSettingsForWritingToApas(): void {
    this.dmsSettings.transferMode = TransferMode.Clipboard;
    this.dmsSettings.transferFormat = TransferFormat.Apas;
  }

  private getDealerData(): DealerData {
    const user: UserData = this.userService.userSubject.getValue();
    return new DealerData(
      user.getBusinessNumber(),
      user.getBusinessPartner()?.getDistributionPartnerNumber(),
      user.getBusinessPartner()?.getOutletNumber()
    );
  }

  private instantiateDmsApi(): void {
    const dmsApiTransferSettings: Settings = this.getDmsApiTransferSettings();

    if (dmsApiTransferSettings.transferMode !== TransferMode.Undefined) {
      DmsService.loadDmsScripts();
    }

    this.dmsApi = DmsService.createDmsImpl(dmsApiTransferSettings, this.getDealerData());
    DmsService.createDmsStub(this.dmsApi, this.dmsIntercom);

    // only publish DMS settings if they have actually changed
    if (!this.previousOutletConfig || !this.previousEtkConfig
      || JSON.stringify(this.previousOutletConfig) !== JSON.stringify(this.outletRelatedConfig)
      || JSON.stringify(this.previousEtkConfig) !== JSON.stringify(this.etkConfig)) {
      this.previousOutletConfig = this.outletRelatedConfig;
      this.previousEtkConfig = this.etkConfig;
      this.dmsSettingsChangedSubject.next(dmsApiTransferSettings);
    }
  }

  private getDmsApiTransferSettings(): Settings {
    const user: UserData = this.userService.userSubject.getValue();
    return new Settings(
      this.getTransferModeForDmsApi(),
      user.getLocale(),
      user.getB2XCountryCode(),
      this.dmsSettings.transferFileStorageLocation,
      this.dmsSettings.etk.usDirectFilename,
      this.dmsSettings.transferFormat,
      this.dmsSettings.dcom.url,
      this.getEtkTransferSettings(),
      this.getServiceContractsConversionMethod(),
      user.getDmsUsername() || null,
      user.getDmsPassword() || null,
      this.getPricesAndAvailabilitiesRequestMethod(),
      ThinClientModeService.thinClientMode,
      undefined,
      undefined,
      this.dmsSettings.dcom.overwriteMetadataOnUpdate,
      this.getDcomUseUserTimezone()
    );
  }

  private getServiceContractsConversionMethod(): ServiceContractsConversionSettings {
    return new ServiceContractsConversionSettings(
      this.outletRelatedConfig?.outletConfiguration?.serviceContractDmsTransferMethod,
      this.outletRelatedConfig?.outletConfiguration?.serviceContractDmsTransferNumber
    );
  }

  private getTransferModeForDmsApi(): TransferMode {
    // Transfer mode needs to remain Dcom, if Dcom is configured to request prices or search via license plate
    return this.dmsSettings.transferMode === TransferMode.Undefined && this.isDcomConfigured() ?
      TransferMode.Dcom :
      this.dmsSettings.transferMode;
  }

  private getDcomUseUserTimezone(): DcomDateFormat {
    return this.outletRelatedConfig?.outletConfiguration.dcomUseUserTimezone
      ? DcomDateFormat.ISO8601_WITH_TIMEZONE_OFFSET
      : DcomDateFormat.ISO8601_IN_UTC;
  }

  private getPricesAndAvailabilitiesRequestMethod(): PricesAndAvailabilitiesRequestMethod {
    return this.dmsSettings.dcom.requestPricesAndAvailabilitiesPerJob
      ? PricesAndAvailabilitiesRequestMethod.ONE_REQUEST_PER_JOB
      : PricesAndAvailabilitiesRequestMethod.ONE_REQUEST_FOR_ALL_JOBS;
  }

  private getEtkTransferSettings(): EtkSettings {
    return this.etkConfig ?
      new EtkSettings(
        this.etkConfig.dmsUrl,
        this.etkConfig.dmsUsername,
        this.etkConfig.firmaNr,
        this.etkConfig.filialeNr
      ) :
      null;
  }

  private mergeOrderAndAppointmentResults(orders: OrderInfo[],
                                          appointments: AppointmentInfo[]): OrderAndAppointmentSearchResult[] {
    const result: OrderAndAppointmentSearchResult[] = [];
    orders.forEach((order: OrderInfo): void => {
      result.push({
        isDmsPreOrder: false,
        dmsId: order.serviceOrderNumber,
        customer: order.customer,
        vehicle: order.vehicle,
        lastChangedDateTime: order.lastChangedDateTime
      });
    });
    appointments.forEach((appointment: AppointmentInfo): void => {
      result.push({
        isDmsPreOrder: true,
        dmsId: appointment.serviceAppointmentId,
        customer: appointment.customer,
        vehicle: appointment.vehicle,
        lastChangedDateTime: appointment.lastChangedDateTime
      });
    });
    return result.sort((a: OrderAndAppointmentSearchResult, b: OrderAndAppointmentSearchResult): number => {
      return a.lastChangedDateTime < b.lastChangedDateTime ? 1 : a.lastChangedDateTime > b.lastChangedDateTime ? -1 : 0;
    });
  }
}
