export class DcomDmsSettings {
  overwriteMetadataOnUpdate: boolean = false;
  isPricesAndAvailabilitiesEnabled: boolean = false;
  isAutomaticPriceRequestEnabled: boolean = false;
  requestPricesAndAvailabilitiesPerJob: boolean = false;
  isLicensePlateSearchEnabled: boolean = false;
  workplaceId: string = undefined;
  bmwWorkplaceId: string = undefined;
  bmwiWorkplaceId: string = undefined;
  miniWorkplaceId: string = undefined;
  rollsRoyceWorkplaceId: string = undefined;
  zinoroWorkplaceId: string = undefined;
  bmwMotorradWorkplaceId: string = undefined;
  url: string = undefined;
  grpDmsOrderSplitActivated: boolean = false;
  grpDmsOrderSplitCostUnitAccountId: string = undefined;
  grpDmsOrderSplitCostUnitDescription: string = undefined;
  sipDmsOrderSplitActivated: boolean = false;
  sipDmsOrderSplitCostUnitAccountId: string = undefined;
  sipDmsOrderSplitCostUnitDescription: string = undefined;
  tecDmsOrderSplitActivated: boolean = false;
  tecDmsOrderSplitCostUnitAccountId: string = undefined;
  tecDmsOrderSplitCostUnitDescription: string = undefined;
}
