import {
  Customer,
  CustomerAddress,
  CustomerContact,
  CustomerInfo,
  CustomerSource,
  CustomerVehicleRelation,
  CustomerWithVehicles
} from '@service-and-repairs/calm-leads-customer-lib-api';

const address: CustomerAddress = {
  street: 'Customer Street',
  streetNo: '456',
  postalCode: '12345',
  city: 'Customer city',
  state: 'Customer state',
  country: 'Customer country',
  extraAddressLine1: null,
  extraAddressLine2: null
};

const contact: CustomerContact = {
  phone: '+12 345 67890',
  email: 'john.doe@mail.com',
  mobile: '+23 456 7890'
};

const johnDoe: Customer = {
  customerTypeCollection: {
    gcdmCustomerTypes: [],
    dmsCustomerTypes: [],
    retailCrmCustomerTypes: [],
    wholesaleCrmCustomerTypes: []
  },
  nameCollection: {
    name: 'John Doe',
    firstName: 'John',
    lastName: 'Doe'
  },
  source: CustomerSource.RETAIL_CRM,
  connectedDriveUsername: 'john.doe',
  isPreferred: true,
  idCollection: {
    dmsId: undefined,
    gcid: undefined,
    retailCrmOkuNumber: 'retail-id-123'
  },
  contactCollection: {
    primaryContact: contact,
    businessContact: null,
    privateContact: null,
    secondaryContact: null
  },
  addressCollection: {
    privateAddress: address,
    billingAddress: null,
    otherAddress: null,
    mailingAddress: null,
    businessAddress: null
  }
};

const janeDoe: Customer = {
  customerTypeCollection: {
    gcdmCustomerTypes: [],
    dmsCustomerTypes: [],
    retailCrmCustomerTypes: [],
    wholesaleCrmCustomerTypes: []
  },
  nameCollection: {
    name: 'Jane Doe',
    firstName: 'Jane',
    lastName: 'Doe'
  },
  source: CustomerSource.DMS,
  connectedDriveUsername: 'jane.doe',
  isPreferred: true,
  idCollection: {
    dmsId: 'dms-customer-123',
    gcid: undefined,
    retailCrmOkuNumber: undefined
  },
  contactCollection: {
    primaryContact: contact,
    businessContact: null,
    privateContact: null,
    secondaryContact: null
  },
  addressCollection: {
    privateAddress: address,
    billingAddress: null,
    otherAddress: null,
    mailingAddress: null,
    businessAddress: null
  }
};

export const customerTestData: Customer[] = [johnDoe, janeDoe];

export const searchByCustomerNameTestData: CustomerWithVehicles[] = [
  {
    customer: johnDoe,
    vehicles: [
      {
        vin: 'WBAJB1C50JG623801',
        brand: 'BMW',
        numberPlate: 'M-IA 123',
        relationType: CustomerVehicleRelation.DMS_PAYER_AFTERSALES,
        modelDesignation: 'X6M'
      }
    ]
  },
  {
    customer: janeDoe,
    vehicles: []
  }
];

export const fetchAllCustomersTestData: CustomerInfo = {
  customers: [johnDoe, janeDoe],
  dmsCustomerFurtherNotes: johnDoe.furtherNotes,
  dmsFurtherNotesCustomerId: johnDoe.idCollection.dmsId
};
